<template>
  <div>
    <tableList></tableList>
  </div>
</template>

<script>
import tableList from './components/tableList' 
export default {
  name: "handlePageIndex",
  components:{
    tableList
  },
  data() {
    return {
      
    };
  },
  computed: {
  },
  watch:{

  },
  created(){
    
  },
  methods:{

    
  }
};
</script>

<style scoped>

</style>