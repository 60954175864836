<template>
  <div class="orderList-view view pa24">
    <el-row :gutter="24">
    </el-row>
    <!-- :spanMethod="objectSpanMethod" -->
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="pageNum"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <!-- <el-table-column prop="createTime" width="100" align="center" label="创建时间">
        </el-table-column> -->
        <el-table-column prop="handle" align="center" label="标识">
        </el-table-column>
        <el-table-column prop="title" align="center" label="页面标题">
        </el-table-column>
        <el-table-column align="center" label="头像">
          <template slot-scope="scope">
            <img :src="scope.row.avatarUrl?scope.row.avatarUrl : '../../../assets/images/avatarDefault.png'" class="avatar"/>
          </template>
        </el-table-column>
        <el-table-column prop="nickeName" align="center" label="昵称">
        </el-table-column>
        <el-table-column prop="address" align="center" label="地址">
        </el-table-column>
        <!-- <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <template v-if="scope.row.isDel">
              <el-button size="mini">删除</el-button>
            </template>
            <el-button size="mini" @click="lookPage(scope.row)">查看</el-button>
            <el-button size="mini" class="tag-read" @click="copyLink(scope.row)" :data-clipboard-text="handleLink">复制链接</el-button>
          </template>
        </el-table-column> -->
      </template>
    </commonTable>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import commonTable from "@/components/common/commonTable";
import { fetchHandlePageList, fetchCreateHandle, fetchVerPageList } from '@/api/customPage'
export default {
  name: "customlist",
  components: {
    commonTable,
  },
  data() {
    return {
      pickerValue: "", //下单时间
      loading:false,
      total: 0, //总条数
      currentPage: 1, //当前页数
      start:'',
      end:'',
      tableData: [],
      pageNum: 1,
      pageSize: 10,

      customPageId: undefined,
      handle: '',
      lotNumber: undefined,
      handleLink: undefined, // 复制链接
    };
  },
  created() {
    if(this.$route.query) {
      this.customPageId =this.$route.query.id
      this.handle = this.$route.query.handle
      this.selectOrdersInfoPcAsync();
    }
  },
  watch:{
  },
  filters: {
  },
  methods: {
    /**@method 获取页面列表 */
    async selectOrdersInfoPcAsync() {
      let params = {
        CId: this.customPageId,
        handle: this.handle,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      const result = await fetchVerPageList(params)
      this.tableData = result.data.list
      this.total = result.data.total
      console.log(result)
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.selectOrdersInfoPcAsync();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.selectOrdersInfoPcAsync();
    }
  },
};
</script>

<style scoped lang='scss'>
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}
</style>
